@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @keyframes first-loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    }
  }
  @keyframes loop {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .scroll-container {
    --time: 36s;
    --half-time: calc(var(--time) / 2);

    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'scroll-area';
  }

  .scroll-container:hover .scroll {
    animation-play-state: paused;
  }

  .scroll {
    grid-area: scroll-area;
    animation: loop var(--time) linear infinite;
    transform: translateX(100%);
  }

  .scroll:first-child {
    animation: first-loop var(--time) linear forwards,
      var(--time) loop var(--half-time) linear infinite;
  }
}
